
import { defineComponent, ref, watch, reactive, toRefs, onMounted } from 'vue';

export default defineComponent({
  props: {
    swiperIndex: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const isAppshow = ref(false);
    const isAndShow = ref(false);
    const data = reactive({
      nowPage: 0,
      emoShow: false
    });

    watch(
      () => props.swiperIndex,
      (newVla) => {
        data.nowPage = Number(newVla);
      }
    );

    onMounted(() => {
      data.nowPage = 0;
    });
    return {
      ...toRefs(data),
      isAndShow,
      isAppshow
    };
  }
});
